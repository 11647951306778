html, body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.bg-novo {
  background-color: #ff6900 !important;
}

.color-novo {
  color: #ff6900 !important;
}

.border-novo {
  border-color: #ff6900 !important;
}

.bg-orange {
  background-color: rgb(240, 111, 34)  !important;
 
}

.link {
  color: white  !important;
  font-family: main;
  font-style: italic;
}

.description {
  font-size: 1.2em;
}

.custom-toggler.navbar-toggler {
  background-color: #ff6900 !important;
  color: #ff6900 !important;
}

.logo {
  width: auto!important;
  -webkit-transition: all 0.4s ease-in-out !important;
  max-height: 3em;
  float: none !important;
  vertical-align: middle !important;
}

.social-icon {
  width: auto;
  max-height: 1em;
}

.sobre {
  color: white;
  background-color: rgb(240, 111, 34);
}

.propostas {
  color: white;
  background-color: #ffcd02;
  color: rgb(0, 57, 115);
}

.compromissos {
  color: white;
  background-color: rgb(0, 57, 115);
}

.group {
  padding: 2em 0;
  margin: 0;
  text-align: justify;
}

@font-face {
  font-family: main;
  src: url('Montserrat-ExtraBold.ttf')  format('truetype');
}

h2 {
  text-align: center;
  font-family: main;
  font-style: italic;
}

a {
  text-align: center;
  font-family: main;
  font-style: italic;
}

.picture {
  max-width: 100% !important;
  padding-bottom: 2em;
}

.quero-apoiar {
  font-size: 1.3em;
}

.footer {
  background-color: rgb(0, 57, 115);
  color: white;
}

.logo-footer {
  margin-top: 10%;
  width: 90%;
}

.icon-footer {
  margin-top: 20%;
  width: 90%;
}

.ig-link {
  color:white;
  font-size: 0.8em;
}

.day-footer {
  margin-top: 10%;
  width: 90%;
}

.contribua {
  width: 80%;
  min-width: 250px;
  text-align: center;
  margin: auto;
}

.menu-icon {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
  margin-left: 1em;
}

.logo-middle {
  max-width: 10em;
}